<template>
  <div class="main">
    <div class="openhong">
      <!-- <div class="close" @click="hongbaoState1 = false">
        <img src="./img/cancel.png" alt="" />
      </div> -->
      <!-- <div class="title">
        CDK兑换
      </div> -->
      <div class="topss">
        <div class="title">
        口令红包
        </div>
        <div class="all">
          <input type="text" v-model="hongbaoText">
          <span @click="getHongbao">兑换</span>
        </div>
      </div>
      <div class="cont">
        <div class="item" v-for="item in hongbaoList" :key="item.id">
          <div class="xian"></div>
          <div class="shang">
            <span class="left">当如累计值，不可重复领取</span>
            <span class="right">总数量：{{item.total_num}}</span>
          </div>
          <div class="xia">
            <div class="left">
              <img src="./img/2.gif" alt="">
              <div class="content">
                <span class="t1">参与条件：每日充值{{item.recharg_money}}币即可领取</span>
                <span>结束时间：{{item.end_time}}</span>
              </div>
            </div>
            <div class="right">
              <span v-if="item.is_qu==0" @click="gotHongbao(item.id)">领取</span>
              <span v-if="item.is_qu==1">已领取</span>
              <span v-if="item.is_qu==2">不可领取</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <HomeBot></HomeBot>
    </div>
    </div>
    <div class="zhe" v-if="hongbaoState2"></div>
    <div class="win-box" v-if="hongbaoState2">
			<div class="win">
				<div class="win-conm">
					<div class="win-tips" style="color:white">恭喜获得</div>
				</div>
				<div class="win-conm" style="color: white;display: flex;align-items: center;flex-flow: row;">
					<div>{{my_hongbao}}</div><img src="./img/money.png" style="width: 24px;">
				</div>
				<div class="win-span2" @click="hongbaoState2 = false">放入背包</div>
			</div>
		</div>
    
  </div>
</template>

<script>
import HomeBot from './my_homebot.vue'
export default {
    components:{
        HomeBot
    },
    data() {
      return {
        hongbaoText:'',
        hongbaoList:[],
        hongbaoid:'',
        hongbaoState2:false,
      }
    },
    mounted() {
      this.getHongbaoList()
      this.openHongbao()
    },
    methods: {
      // 领取红包
    gotHongbao(id) {
      this.$axios({
        url: '/api/welfare/envelopeentryqu',
        method: 'post',
        data:{id:id}
      }).then((res) => {
        if(res.data.code ==1) {
          this.$message({
            message:res.data.msg,
            type:'success',
            customClass: "messageStyle",
          })
          this.my_hongbao = res.data.data.money
          this.hongbaoState2 = true
          this.getHongbaoList()
        }else{
          this.$message({
            message:res.data.msg,
            type:'warning',
            customClass: "messageStyle",
          })
        }
      })
    },
      // 获取红包列表
    getHongbaoList() {
      this.$axios({
        url:'/api/welfare/envelopeentrylist',
        methos: 'post'
      }).then((res) => {
        if(res.data.code == 1) {
          this.hongbaoList = res.data.data
        }
      })
    },
    openHongbao() {
      // this.drawer = false
      let param = {};
      this.$axios
        .post("/api/welfare/existenvelope", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.code == 1) {
            this.hongbaoid = data.data.id;
            //未抢
            if (data.data.status == 0) {
              // this.hongbaoState1 = true;
            } else {
              // this.hongbaoState1 = true;
              //this.hongbaoList()
            }
          } else {
            if (data.msg == "参数错误") {
              this.$message({
                message: "请先登录",
                type: "warning",
                customClass: "messageStyle",
              });
              this.$store.commit("getLogin", true);
            } else {
              this.$message({
                message: "暂时没有红包哦",
                type: "warning",
                customClass: "messageStyle",
              });
            }
          }
        });
    },
       //领取红包
    getHongbao() {
      if (!this.hongbaoText) {
        this.$message({
          message: "请输入红包口令",
          type: "warning",
          customClass: "messageStyle",
        });
        return;
      }
      // this.hongbaoState1 = false;
      // this.hongbaoState2 = true;
      let param = {
        password: this.hongbaoText,
      };
      this.$axios
        .post("/api/welfare/envelope", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.code == 1) {
            // this.hongbaoState1 = false;
            this.hongbaoState2 = true;
            this.my_hongbao = data.data.gotMoney;
            // this.hongbaoList();
            this.getHongbaoList()
            // this.getPlayerInfo();
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
              customClass: "messageStyle",
            });
          }
        });
    },
    }
}
</script>

<style lang="less" scoped>
.main{
    width: 100vw;
    height: calc(100vh - 300px);
    background-color: #0E1325;
}
.openhong{
  width: 100%;
//   height: 600px;
  height: 100%;
//   position: fixed;
//   border-radius: 10px;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%,-50%);
  // background-color: aqua;
//   background-image: url(./img/hongbao-bg.png);
  background-size: 100% 100%;
  z-index: 9999999;
  padding: 0 6px;
  box-sizing: border-box;
  .title{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  .topss{
    width: 100%;
    height: 25vw;
    border-radius: 10px;
    background-color: rgba(105, 23, 255, .5);
    margin-top: 10px;
    position: relative;
    box-sizing: border-box;
    padding-top: 10px;
    .all{
      width: 100%;
      display: flex;
      align-items: center;
    //   background-color: rgba(105, 23, 255, .5);
    }
    span{
    //   width: 100px;
      width: 20%;
      height: 35px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ff31ed;
      cursor: pointer;
      margin-left: 10px;
    }
    input{
      // margin: 20px;
      background-color: hsla(0, 0%, 100%, .8);
      color: #000;
    //   width: 150px;
      width: 65%;
      height: 35px;
      margin-left: 10px;
      outline: none;
      border: none;
      border-radius: 3px;
      padding: 15px;
      box-sizing: border-box;
    }
  }
  .cont{
    width: 100%;
    // height: 66%;
    // height: 140vw;
    // background-color: red;
    margin-top: 12px;
    // overflow-y: scroll;
    // margin-bottom: 130px;
    // padding-bottom: 130px;
    .item{
      width: 100%;
    //   height: 175px;
      height: 32vw;
      background: hsla(0, 0%, 100%, .05);
      margin-bottom: 12px;
      border-radius: 5px;
      padding: 4%;
      box-sizing: border-box;
      position: relative;
      .xian{
        position: absolute;
        left: 0;
        top: 22.5%;
        width: 4px;
        height: 55%;
        background-color: #6917ff;
      }
      .shang{
        width: 100%;
        // height: 50px;
        height: 35%;
        background-color: #6917ff;
        color: white;
        padding: 0 4%;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          font-size: 15px;
          font-weight: 700;
        }
        .right{
          color: #d1d1d1;
          font-size: 12px;
        }
      }
      .xia{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3%;
        // background-color: rgba(189, 187, 187, 0.486);
        .left{
          height: 100%;
          display: flex;
          align-items: center;
          .content{
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            font-size: 13px;
            color: #d1d1d1;
            .t1{
              font-size: 14px;
              color: white;
              margin-bottom: 8px;
            }
          }
          img{
            width: auto;
            height: 90%;
          }
        }
        .right{
          width: 68px;
          height: 40px;
          background-image: url(./img/button-bg.png);
          background-size: 100% 100%;
          line-height: 40px;
          text-align: center;
          color: white;
          font-weight: 700;
          cursor: pointer;
          margin-left: 3px;
          font-size: 14px;
        }
      }
    }
  }
}
.bottom{
    width: 100%;
    // position: fixed;
    // bottom: 0;
}

.win-box {
  // width: 100vw;
  // height: 100vh;
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    height: 300px;
    width: 400px;
    // background-color: rgba(0, 0, 0, 0.7);
    // display: grid;
    // place-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .win {
    width: 450px;
    padding: 20px;
    background-color: #1c2230;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .win-span2 {
      padding: 10px 100px;
      height: 20px;
      background-color: #e9b10e;
      background-size: 100% 100%;
      border: 1px solid #ffea95;
      color: #000000;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 15px;
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }
  .zhe{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
</style>